body {
  margin: 0;
  padding: 0;
  font-family: adobe-garamond-pro, serif;
  font-style: normal;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  position: relative;
  background-repeat: no-repeat;
  background-color: #2e3750;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/**
sammy stuff below
 */

html, body, #root {
  height: 100%;
}

*, *:before, *:after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

.gallery-container {
  padding: .9375rem 0;
}

.gallery-container h1 {
  margin: 2rem 0;
  padding: 0;
  text-align: center;
  color: #fff;
  font-size: 6.5vw;
  font-weight: lighter;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.grid-item:hover {
  /* border: 2px solid #d9d9d9; */
}

@media (min-width: 20em) {
  .gallery-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 34em) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 60em) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.gallery-grid div {
  position: relative;
  border-radius: 5px;
  /* border: 2px solid #737373; */
}

/* Style all font awesome icons */
.fa {
  padding: 5px;
  font-size: 33px;
  /* width: 30px; */
  text-align: center;
  text-decoration: none;
  border-radius: 10%;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
}

/* Set a specific color for each brand */

.fa-linkedin {
  background: #007bb5;
  color: white;
  margin-left: 20px;
}

/* Twitter */
.fa-twitter {
  background: #55ACEE;
  color: white;
}

